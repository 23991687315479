const staticData = {
    static: {
        sections: [
            {
                title: 'Top Searches',
                slug: 'top-searches',
                searches: [
                    {
                        title: 'cars',
                    },
                    {
                        title: 'Barbie',
                    },
                    {
                        title: 'Disney',
                    },
                    {
                        title: 'lobster',
                    },
                    {
                        title: 'dog',
                    },
                    {
                        title: 'Halloween',
                    },
                    {
                        title: 'pizza',
                    },
                    {
                        title: 'kimono',
                    },
                    {
                        title: 'tennis',
                    },
                    {
                        title: 'football',
                    },
                    {
                        title: 'princess',
                    },
                ],
            },
            {
                title: 'Top Classes + Events',
                slug: 'top-classes-events',
                classes: [
                    {
                        title: 'Jam With Jamie Summer Series',
                        url: '/products/jam-with-jamie-summer-music-session-series-in-person-chicago-nyc',
                    },
                    {
                        title: 'Pregnant + New Parent Meet Up (NYC)',
                        url: '/products/pregnant-new-parent-meet-up-nyc',
                    },
                    {
                        title: 'Baby Bandstand',
                        url: '/products/baby-bandstand',
                    },
                    {
                        title: 'Perinatal Yoga with Carla Jian',
                        url: '/products/perinatal-yoga-with-carla-jian',
                    },
                    {
                        title: 'Infant First Aid + CPR',
                        url: 'https://monicaandandy.com/products/infant-first-aid-and-cpr',
                    },
                    {
                        title: 'Potty Training 101 with Miss Catherine',
                        url: '/products/potty-training-101-with-miss-catherine-consulting',
                    },
                    {
                        title: 'Naptime with Sleep Expert Kelly Murray',
                        url: '/products/naptime-with-sleep-expert-kelly-murray',
                    },
                ],
            },
            {
                title: 'Top Categories',
                slug: 'top-categories',
                categories: [
                    {
                        title: 'Rompers + Bodysuits',
                        url: '/collections/all-rompers',
                        imageSrc:
                            'https://cdn.shopify.com/s/files/1/2338/2917/files/search-flyout-on-the-go-one-piece-pizza-lovers_600x600.jpg?v=1693413696',
                        imageAlt: 'On the Go One-Piece in Pizza Lovers print',
                    },
                    {
                        title: 'Blankets',
                        url: '/collections/baby-blankets',
                        imageSrc:
                            'https://cdn.shopify.com/s/files/1/2338/2917/files/search-flyout-blankets-coming-home-blanket-navy-elephant_600x600.jpg?v=1693413697',
                        imageAlt: 'Coming Home Blanket in Navy Elephant print',
                    },
                    {
                        title: 'Dresses',
                        url: '/collections/all-dresses',
                        imageSrc:
                            'https://cdn.shopify.com/s/files/1/2503/2026/files/search-flyout-dresses-double-ruffle-elsa_600x600.webp?v=1699461584',
                        imageAlt:
                            "Double Ruffle Let's Dance Dress in Elsa print",
                    },
                    {
                        title: 'Pajamas',
                        url: '/collections/all-pajamas',
                        imageSrc:
                            'https://cdn.shopify.com/s/files/1/2338/2917/files/search-flyout-pajamas-two-piece-pajama-set-prancing-unicorn_600x600.jpg?v=1693413696',
                        imageAlt:
                            'Two-Piece Pajama Set in Prancing Unicorn print',
                    },
                    {
                        title: 'Gifts + Sets',
                        url: '/collections/baby-gifts',
                        imageSrc:
                            'https://cdn.shopify.com/s/files/1/2338/2917/files/search-flyout-gifts-sets-hospital-cuddle-box-pink_600x600.jpg?v=1694013759',
                        imageAlt: 'Hospital Cuddle Box in Pink Rainbow',
                    },
                    {
                        title: 'Nursery',
                        url: '/collections/nursery',
                        imageSrc:
                            'https://cdn.shopify.com/s/files/1/2338/2917/files/search-flyout-nursery-crib-white_600x600.jpg?v=1694013759',
                        imageAlt: 'Babyletto crib in White',
                    },
                    {
                        title: 'Accessories',
                        url: '/collections/all-accessories',
                        imageSrc:
                            'https://cdn.shopify.com/s/files/1/2338/2917/files/search-flyout-accessories-top-knot-cap-navy-elephant_600x600.jpg?v=1693537942',
                        imageAlt: 'Top Knot Cap in Navy Elephant print',
                    },
                    {
                        title: 'Robes',
                        url: '/collections/organic-lounge-robes',
                        imageSrc:
                            'https://cdn.shopify.com/s/files/1/2338/2917/files/search-flyout-maternity-organic-lounge-robe-grey_600x600.jpg?v=1694013760',
                        imageAlt:
                            'Organic Lounge Robe in grey folded with a bow',
                    },
                    {
                        title: 'Outfits + Bundles',
                        url: '/collections/outfits-bundles',
                        imageSrc:
                            'https://cdn.shopify.com/s/files/1/2503/2026/files/search-flyout-outfits-sporty-sweatshirt-ski-squad_600x600.webp?v=1699461584',
                        imageAlt:
                            'Sporty Sweatshirt + Sweatpant Bundle in Ski Squad print',
                    },
                ],
            },
        ],
    },
}

export default staticData

import { Fragment } from 'react'

import Highlight from '../Highlight/Highlight.jsx'

const SearchSuggestions = ({ query, suggestions, handleSuggestionClick }) => {
    return (
        <Fragment>
            {suggestions && suggestions.length > 0 ? (
                <div className="search-suggestions-outer">
                    <div className="search-suggestions-inner">
                        <h3 className="search-suggestions-title text-base xl:text-lg mb-2">
                            Search Suggestions
                        </h3>
                        <ul className="suggestions-list nostyle">
                            {suggestions.map((s, i) => (
                                <li key={i}>
                                    <span
                                        className="suggestion-text text-sm text-flint hover:text-ash hover:underline cursor-pointer"
                                        onClick={() => handleSuggestionClick(s)}
                                    >
                                        <Highlight search={query.q}>
                                            {s}
                                        </Highlight>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ) : null}
        </Fragment>
    )
}

export default SearchSuggestions
